import styled from "styled-components"
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import StarRatings from "react-star-ratings"
import moment from "moment"

const Reviews = ({ allReviews, productId, product }) => {
  // const [expand, setExpand] = useState(false)
  const [reviews, setreviews] = useState(null)
  // const numberOfReviews = expand ? reviews.length : 3

  // const showMore = () => {
  //   setExpand(!expand)
  // }

  // useEffect(() => {
  // let filteredReviews = allReviews.filter(item => {
  //   // return item
  //   return item.productIdentifier.toString() === productId.toString()
  // })
  // setreviews(filteredReviews)
  // console.log(productId, "reviews")
  // }, [])

  // if (!reviews) return <div></div>
  return (
    <ReviewContainer>
      {/* REVIWES  */}
      <div
        className="yotpo yotpo-main-widget"
        data-product-id={productId}
        data-price={product.variants[0].price}
        data-currency="USD"
        data-name={product.title}
        data-url={`www.belgianboys.com/shop/${product.handle}`}
        data-image-url={product.images.originalSrc}
      ></div>
      {/* <h1>Reviews ({reviews.length})</h1>
      <div>
        {reviews.slice(0, numberOfReviews).map((review, index) => {
          var reviewContent = document.createElement("textarea")
          reviewContent.innerHTML = review.content

          return (
            <div
              key={review.id}
              className="review"
              style={{ marginTop: "50px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <StarRatings
                  rating={review.score}
                  starRatedColor="var(--darkBlue)"
                  numberOfStars={5}
                  starDimension="20px"
                  starSpacing="3px"
                  name="rating"
                />
                <div>
                  <p>{moment(review.createdAt).format("MM/DD/YYYY")}</p>
                </div>
              </div>
              <p className="content">{reviewContent.value}</p>
            </div>
          )
        })}
        {!expand && <div className="line"></div>}
        <button className="expand-btn" onClick={showMore}>
          {expand ? "Show less" : "Expand for more"}
        </button>
      </div> */}
    </ReviewContainer>
  )
}

const ReviewContainer = styled.div`
  margin: 100px auto;
  color: var(--darkBlue);

  .promoted-products-box {
    display: none !important;
  }

  .line {
    margin-top: 30px;
    width: 100%;
    height: 1px;
    background: var(--darkBlue);
  }
  .expand-btn {
    margin: 50px auto;
    border: none;
    color: var(--darkBlue);
    background: transparent;
    width: 100%;
    text-decoration: underline;
    font-size: 1em;
    text-transform: uppercase;
  }

  .review {
    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s; /* Firefox < 16 */
    -ms-animation: fadein 3s; /* Internet Explorer */
    -o-animation: fadein 3s; /* Opera < 12.1 */
    animation: fadein 3s;
  }

  .content {
    width: 70%;
    margin-top: 15px;
  }
  @media (max-width: 1024px) {
    margin: 0 auto;
    width: 90%;

    .content {
      width: 100%;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
export default Reviews
