import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../layout"

import SEO from "../seo"
import styled from "styled-components"
import Image from "gatsby-image"
import BundleItem from "./BundleItem"
import { useBundle } from "../../hooks/useBundle"
import { useStore } from "../../context/TestContext"

const PopUpBundle = ({
  packChoice,
  product,
  setPopup,
  breakfastSmall,
  breakfastBig,
  dessertSmall,
  dessertBig,
}) => {
  const { isLoading } = useStore()
  // console.log("packChoice:", console.log(packChoice.split("$")))
  const [bundleType, setBundleType] = useState(null)
  const bundleSize = packChoice.includes("5") ? 5 : 10
  // const chosenBundlePrice = packChoice.split("$")[
  //   packChoice.split("$").length - 1
  // ]
  const chosenBundlePrice = packChoice.includes("5") ? 60 : 100

  useEffect(() => {
    if (product.tags.includes("dessertPick")) {
      if (packChoice.includes("5")) {
        setBundleType(dessertSmall)
      } else {
        setBundleType(dessertBig)
      }
    } else {
      if (packChoice.includes("5")) {
        setBundleType(breakfastSmall)
      } else {
        setBundleType(breakfastBig)
      }
    }
  }, [])

  const {
    bundleChoices,
    addToBundler,
    counter,
    remove,
    reallyRemove,
    warn,
    addBundleToCart,
    progressBar,
  } = useBundle({
    size: bundleSize,
    product,
    setPopup,
    price: chosenBundlePrice,
  })

  if (!bundleType) {
    return <div></div>
  }
  return (
    <>
      <PopUp>
        <div className="select-options-container">
          <div className="header">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h1>Pick any {bundleSize} </h1>
              <button className="close" onClick={() => setPopup(c => !c)}>
                X
              </button>
            </div>
            {/* <p>Cheescakes, lava cakes, pancakes, or crepês.</p> */}
            <h1>$ {product.variants[0].price}</h1>
          </div>
          <div className="option-list-div">
            <ul>
              {bundleType.map((el, i) => (
                <BundleItem
                  addToBundler={addToBundler}
                  bundleChoices={bundleChoices}
                  bundleSize={bundleSize}
                  remove={remove}
                  counter={counter}
                  key={el.id}
                  index={i}
                  reallyRemove={reallyRemove}
                  el={el}
                />
              ))}
            </ul>
          </div>

          <AddBundle>
            <ProgressBar size={bundleSize}>
              {progressBar.map((el, i) => (
                <Progress key={i} state={el} />
              ))}
            </ProgressBar>
            <AddToCartBtn
              onClick={addBundleToCart}
              disabled={isLoading || bundleSize !== bundleChoices.length}
            >
              Add to Cart
            </AddToCartBtn>
          </AddBundle>
          <div className="warnWrapper">
            {warn && <p className="warning">{warn}</p>}
          </div>
        </div>

        <NonVisibleToCLick onClick={() => setPopup(c => !c)} />
      </PopUp>
    </>
  )
}
export default PopUpBundle

const NonVisibleToCLick = styled.div`
  position: fixed;
  height: 100vh;
  top: 0;
  width: 100vw;
  z-index: -1;
`

const Progress = styled.div`
  background: ${props => (props.state ? "var(--pink)" : "none")};
  height: ${props => (props.state ? "calc(100% - 4px)" : "100%")};
  width: 100%;
  border-radius: ${props => (props.state ? "20px" : "0")};
  align-self: center;
  border-right: ${props => (props.state ? "none" : "1px solid black")};
  &:last-child {
    border-right: none;
  }
`

const ProgressBar = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.size}, 1fr);
  height: 15px;
  border: 1px solid black;
  border-radius: 20px;
  overflow: hidden;
  align-items: center;
  padding: 0 4px;
  grid-gap: 8px;
  @media (max-width: 769px) {
    grid-gap: 6px;
  }
`

const PopUp = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 1111;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: auto;
  background-color: rgba(1, 1, 1, 0.7);

  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 20px;
    padding-bottom: 10px;
    color: var(--darkBlue);
    h1 {
      color: var(--pink);
      margin: 10px 0;
    }

    .close {
      font-size: 1.8em;
      color: var(--pink);
      border: none;
      background: transparent;
    }

    @media (max-width: 550px) {
      margin-bottom: 10px;
      padding-bottom: 5px;
      h1 {
        margin: 10px 0;
        font-size: 1.5rem;
      }

      .close {
        font-size: 1.5rem;
      }
    }
  }

  .select-options-container {
    width: 40vw;
    min-height: 80vh;
    top: 25%;
    left: 25%;
    background-color: white;
    padding: 30px;
    border-radius: 20px;
  }

  .option-list-div {
    overflow-y: scroll;
    height: 60%;
    padding-right: 20px;

    @media (max-width: 550px) {
      height: 70%;
    }

    ul {
      height: 100%;
      /* align-items: center; */
      flex-direction: column;
      display: flex;
      justify-content: end;
    }

    li {
      font-weight: 500;
      color: var(--darkBlue);
      @media (max-width: 400px) {
        font-size: 14px;
      }
    }
  }
  .button-div {
    font-weight: bold;
    color: var(--pink);
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background: transparent;
      font-weight: bolder;
      padding: 10px 15px;
      border-radius: 50%;
      border: 1px solid var(--pink);
      color: var(--pink);
    }
  }

  .add-bundle {
    margin-top: 50px;
    /* display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center; */
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-content: space-between;
    grid-gap: 2rem;
  }

  .warnWrapper {
    display: flex;
    justify-content: flex-end;
  }
  .warning {
    margin-top: 10px;
    color: var(--darkBlue);
    font-weight: bold;
    text-align: center;
  }

  .add-to-cart-btn {
    background-color: var(--pink);
    color: white;
    font-size: 0.8em;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    border-radius: 20px;
    padding: 15px 25px;
    width: 200px;

    &:hover {
      background-color: var(--pink);
    }
  }

  @media (max-width: 1024px) {
    .select-options-container {
      width: 78vw;
      overflow-y: scroll;
    }

    .add-bundle {
      justify-content: center;
    }
  }
`
const AddBundle = styled.section`
  margin-top: 50px;
  /* display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center; */
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
  grid-gap: 2rem;

  .warning {
    margin-top: 10px;
    color: var(--darkBlue);
    font-weight: bold;
    text-align: center;
  }

  @media (max-width: 1024px) {
    grid-gap: 1rem;
  }

  @media (max-width: 769px) {
    grid-gap: 0.8rem;
  }
  @media (max-width: 600px) {
    margin-top: 30px;
    grid-gap: 0.5rem;
  }
`
const AddToCartBtn = styled.button`
  background-color: var(--lightBlue);

  color: white;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 20px;
  padding: 15px 25px;
  width: 200px;

  &:disabled {
    background: lightgrey;
  }

  @media (max-width: 769px) {
    width: 150px;
    padding: 10px 20px;
  }
`
