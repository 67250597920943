import styled from "styled-components"
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import { forward, backward } from "../../utils/imageUpload"
import { Button, ZoomContainer } from "../../styles/ProductDetailStyles.js"

const Zoom = ({ imgArr, product, imgIndex, zoom, setZoom, setIndex }) => {
  return (
    <ZoomContainer className="zoom">
      <div className="assets-div" style={{ position: "relative" }}>
        {/*   {imgArr.length > 1 && (
          <Button onClick={() => setIndex("back")} style={{ zIndex: "1" }}>
            <img width="15px" src={backward} alt="" />{" "}
          </Button>
        )} */}
        <img
          src={product.images[imgIndex].localFile.childImageSharp.fluid.src}
          alt={product.title}
          style={{ zIndex: "1" }}
        />
        {/*  {imgArr.length > 1 && (
          <Button onClick={() => setIndex("forward")}>
            <img width="15px" src={forward} alt="" />{" "}
          </Button>
        )} */}
        {/* <div className="close" style={{ zIndex: "1" }}>
          <button onClick={() => setZoom(!zoom)}>Exit</button>
        </div> */}
        <div
          style={{
            height: "100%",
            width: "100%",
            // background: "var(--clickOut)",
            // background: "var(--clickOut)",
            position: "absolute",
            top: "0",
            zIndex: "0",
          }}
          onClick={() => setZoom(z => !z)}
        ></div>
      </div>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: "0",
          zIndex: "-1",
        }}
        onClick={() => setZoom(z => !z)}
      />
    </ZoomContainer>
  )
}

export default Zoom
