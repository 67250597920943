import React, { useState } from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

const RelatedProduct = ({ product }) => {
  const [productImgIndex, setproductImgIndex] = useState(0)

  const changeImg = bool => {
    if (bool) {
      setproductImgIndex(1)
    } else {
      setproductImgIndex(0)
    }
  }

  return (
    <Link
      onMouseOver={() => changeImg(true)}
      onMouseOut={() => changeImg(false)}
      to={`/product/${product.handle}`}
      className="item"
    >
      {product.images.length <= 1 || product.tags.includes("bundles") ? (
        <Image
          imgStyle={{
            objectFit: "contain",
          }}
          key={product.handle}
          fluid={product.images[0].localFile.childImageSharp.fluid}
          alt={product.title}
          className={`productImg`}
        />
      ) : (
        <Image
          imgStyle={{
            objectFit: "contain",
          }}
          key={product.handle}
          fluid={
            product.images[productImgIndex].localFile.childImageSharp.fluid
          }
          alt={product.title}
          className={`productImg`}
        />
      )}

      <div className="title-container">
        <p>{product.title.toUpperCase()}</p>
      </div>
    </Link>
  )
}

export default RelatedProduct
