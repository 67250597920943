import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../layout"
import StarRatings from "react-star-ratings"

import SEO from "../seo"
import styled from "styled-components"
import Image from "gatsby-image"
import Related from "./Related"
import Discount from "../../template/Discount"
import Zoom from "./Zoom"
import PopUpBundle from "./PopUpBundle"
import { forward, backward } from "../../utils/imageUpload"
import { useStore } from "../../context/TestContext"
import ReactPixel from "react-facebook-pixel"
import Reviews from "./Reviews"

import {
  ZoomContainer,
  Button,
  Wrapper,
  ImgContainer,
  ProductContainer,
} from "../../styles/ProductDetailStyles.js"
import { useCounter } from "../../hooks/useCounter"
import BodyWrapper from "../BodyWrapper"

const ProductTemplate = ({ data }) => {
  const product = data.shopifyProduct

  //These are the different bundle product options

  const [breakfastSmall] = data.breakfastSmall.edges.map(
    ({ node }) => node.products
  )
  const [breakfastBig] = data.breakfastBig.edges.map(
    ({ node }) => node.products
  )
  const [dessertBig] = data.dessertBig.edges.map(({ node }) => node.products)
  const [dessertSmall] = data.dessertSmall.edges.map(
    ({ node }) => node.products
  )

  const encodedProductId =
    typeof window !== "undefined" &&
    window.atob(product.shopifyId).replace(/\D/g, "")

  const addYoptoScript = () => {
    const yotpoScript = document.createElement("script")
    yotpoScript.type = "text/javascript"
    yotpoScript.async = true
    yotpoScript.id = "yotpoScript"
    yotpoScript.src = `//staticw2.yotpo.com/${process.env.GATSBY_YOTPO_APP_KEY}/widget.js`

    document.body.appendChild(yotpoScript)
  }

  useEffect(() => {
    addYoptoScript()

    return () => {
      const yotpoScriptsArr = document.querySelectorAll("#yotpoScript")
      for (let i = 0; i < yotpoScriptsArr.length; i++) {
        const yotpoScript = yotpoScriptsArr[i]
        yotpoScript.parentNode.removeChild(yotpoScript)
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined" && typeof window.yotpo !== "undefined") {
      window.yotpo.refreshWidgets()
    }
  }, [product])

  const { counter, add, remove } = useCounter(1)

  const includesMultVariants =
    product.variants.length === 1 ? null : product.variants

  const packVariants = !product.variants.length
    ? null
    : product.variants.reduce((acc, val) => {
        return { ...acc, [val.title]: { ...val } }
      }, {})

  const [zoom, setZoom] = useState(false)
  const [imgIndex, setimgIndex] = useState(0)
  const [imgArr] = useState(product.images)
  const [test, setTest] = useState("")
  const [test2, setTest2] = useState("")
  const [idTest, setId] = useState(null)
  const [arr, setArr] = useState([])
  const [popUp, setPopup] = useState(false)

  const [size, setSize] = useState(includesMultVariants)
  const [sizeChoice, setSizeChoice] = useState("medium")
  const [packChoice, setPackChoice] = useState(product.variants[0].title)

  // console.log(packVariants, "Pack VARIANTS")
  const {
    addToCart,
    state,
    addToCartWithCustomStuff,
    updateCustomAttributes,
  } = useStore()

  async function letsSeeThemTests() {
    if (test) {
      const variantId = product?.variants?.[0]?.shopifyId
      const customAttribute = {
        key: "products",
        value: arr.join(""),
      }
      const check = await addToCartWithCustomStuff(customAttribute, variantId)

      setTest("")
      setId(check?.lineItems?.[0]?.id)
    } else {
      return letsSeeThemTests2(idTest)
    }
  }

  const setIndex = (direction, index) => {
    if (direction === "back") {
      if (imgIndex > 0) setimgIndex(imgIndex - 1)
      else return
    } else if (direction === "forward") {
      if (imgIndex < imgArr.length - 1) setimgIndex(imgIndex + 1)
      else return
    } else {
      setimgIndex(index)
    }
  }

  function addToArr() {
    const customAttribute = { key: "product", value: test }

    setArr([...arr, test])
    setTest("1")
  }

  async function letsSeeThemTests2(id) {
    const variantId = product?.variants?.[0]?.shopifyId

    const check2 = await updateCustomAttributes(test2, id)
    setTest2("")
  }

  function addToCartBonanza() {
    const {
      title,
      variants,
      variants: [{ price, shopifyId }],
    } = product

    const image = product.images[0].localFile.childImageSharp.fluid

    const args = {
      title,
      price,
      quantity: counter,
      image,
      variantId: shopifyId,
      variants,
    }

    if (state.isLoading) {
      // console.log("Loading")
      return
    } else if (size !== null) {
      if (product.tags.includes("qtyOption")) {
        const productToAdd = product.variants.find(
          el => el.title === packChoice
        )
        addToCart({
          ...args,
          variantId: productToAdd.shopifyId,
          price: packVariants[packChoice].price,
        })
        ReactPixel.track("AddToCart", {
          content_name: packVariants[packChoice].title,
          contents: packChoice,
          value: packVariants[packChoice].price,
          currency: "USD",
          num_items: counter,
        })
      } else {
        const productToAdd = product.variants.find(
          el => el.title === sizeChoice
        )
        addToCart({ ...args, variantId: productToAdd.shopifyId })
        ReactPixel.track("AddToCart", {
          content_name: product.title,
          contents: sizeChoice,
          value: product.variants[0].price,
          currency: "USD",
          num_items: counter,
        })
      }
    } else {
      addToCart(args)
      ReactPixel.track("AddToCart", {
        content_name: product.title,
        value: product.variants[0].price,
        currency: "USD",
        num_items: counter,
      })
    }
  }

  function onChange(e, type) {
    if (type === "size") {
      setSizeChoice(e.target.value)
    } else {
      setPackChoice(e.target.value)
    }
  }

  return (
    <Layout>
      <SEO title={product.title} />
      <Discount />
      <BodyWrapper>
        <Wrapper>
          {popUp && (
            <PopUpBundle
              packChoice={packChoice}
              setPopup={setPopup}
              breakfastSmall={breakfastSmall}
              breakfastBig={breakfastBig}
              dessertSmall={dessertSmall}
              dessertBig={dessertBig}
              product={product}
              // organizedBundle={[cheeseCakes, lavaCakes, theRest]}
            />
          )}

          {zoom && (
            <Zoom
              imgArr={imgArr}
              product={product}
              imgIndex={imgIndex}
              zoom={zoom}
              setZoom={setZoom}
              setIndex={setIndex}
            />
          )}
          <ProductContainer>
            <div
              className="assets-div"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                {imgArr.length > 1 && (
                  <Button onClick={() => setIndex("back")}>
                    <img src={backward} alt="" />{" "}
                  </Button>
                )}
                <ImgContainer>
                  <div onClick={() => setZoom(!zoom)}>
                    <Image
                      className="detailImg"
                      fluid={imgArr[imgIndex].localFile.childImageSharp.fluid}
                      alt=""
                    />
                  </div>

                  <div className="thumbnail-container">
                    {imgArr.length > 1 &&
                      imgArr.map((el, index) => {
                        if (imgIndex === index) {
                          return (
                            <img
                              key={index}
                              onClick={() => setIndex("", index)}
                              style={{
                                width: "100px",
                                height: "100px",
                                // border: "2px solid var(--pink)",
                                marginRight: "10px",
                              }}
                              src={el.localFile.childImageSharp.fluid.src}
                            />
                          )
                        } else {
                          return (
                            <img
                              key={index}
                              onClick={() => setIndex("", index)}
                              style={{
                                width: "70px",
                                height: "70px",
                                marginRight: "10px",
                                border: "1px solid rgba(0,0,0,0.1)",
                              }}
                              src={el.localFile.childImageSharp.fluid.src}
                            />
                          )
                        }
                      })}
                  </div>
                </ImgContainer>
                {imgArr.length > 1 && (
                  <Button onClick={() => setIndex("forward")}>
                    <img src={forward} alt="" />{" "}
                  </Button>
                )}
              </div>
            </div>
            <div className="info-div">
              <h1 className="title">{product.title}</h1>
              <div className="ratings">
                {encodedProductId && (
                  <div
                    className="yotpo bottomLine"
                    data-product-id={encodedProductId}
                    data-url={`www.belgianboys.com/shop/${product.handle}`}
                  ></div>
                )}
              </div>
              <h1 className="price">
                {product.tags.includes("qtyOption")
                  ? packVariants[packChoice].price
                  : product.variants[0].price}
              </h1>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
              ></div>
              {/* <p className="description">{product.description}</p> */}
              <div className="select-div">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {size &&
                    (product.tags.includes("qtyOption") ? (
                      <>
                        <h3 className="size">Pack:</h3>
                        <select
                          className="select-size"
                          value={packChoice}
                          onChange={e => onChange(e, "pack")}
                        >
                          {size.map(el => (
                            <option key={el.title} value={el.title}>
                              {el.title}
                            </option>
                          ))}
                        </select>
                      </>
                    ) : (
                      <>
                        <h3 className="size">Size:</h3>
                        <select
                          className="select-size"
                          value={sizeChoice}
                          onChange={e => onChange(e, "size")}
                        >
                          {size.map(el => (
                            <option key={el.title} value={el.title}>
                              {el.title}
                            </option>
                          ))}
                        </select>
                      </>
                    ))}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {product.tags.includes("outOfStock") ? (
                    <button
                      disabled
                      className="add-to-cart-btn outOfStock"
                      style={{ width: "100%" }}
                    >
                      Out of stock
                    </button>
                  ) : product.tags.includes("pick") ? (
                    <>
                      <button
                        style={{ width: "100%" }}
                        className="customize-btn"
                        // onClick={letsSeeThemTests}
                        onClick={() => setPopup(c => !c)}
                      >
                        Customize Your Bundle
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="btn-div">
                        <button onClick={remove} className="btn-count">
                          -
                        </button>
                        <p>{counter}</p>
                        <button onClick={add} className="btn-count">
                          +
                        </button>
                      </div>

                      <button
                        className="add-to-cart-btn"
                        onClick={addToCartBonanza}
                      >
                        Add To Cart
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </ProductContainer>
          <Related data={data} product={product} />
        </Wrapper>
        {encodedProductId && (
          <Reviews productId={encodedProductId} product={product} />
        )}
      </BodyWrapper>
    </Layout>
  )
}

export default ProductTemplate

export const query = graphql`
  query SINGLE_PRODUCT($id: String!) {
    shopifyProduct(id: { eq: $id }) {
      handle
      title
      description
      descriptionHtml
      shopifyId
      vendor
      variants {
        title
        shopifyId
        price
        weight
        availableForSale
      }

      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid {
              src
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      tags
    }
    shopifyCollection(handle: { eq: "shop" }) {
      products {
        id
        handle
        description
        descriptionHtml
        title
        shopifyId
        productType
        variants {
          title
          shopifyId
          price
          weight
          availableForSale
        }
        images {
          originalSrc
          id
          localFile {
            childImageSharp {
              fluid {
                src
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tags
      }
    }

    dessertSmall: allShopifyCollection(
      filter: { handle: { eq: "dessertbundlesmall" } }
    ) {
      edges {
        node {
          products {
            id
            title
            variants {
              shopifyId
              price
            }
            images {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    src
                  }
                }
              }
            }
          }
          title
        }
      }
    }

    dessertBig: allShopifyCollection(
      filter: { handle: { eq: "dessertbundlebig" } }
    ) {
      edges {
        node {
          products {
            id
            title
            variants {
              shopifyId
              price
            }
            images {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    src
                  }
                }
              }
            }
          }
          title
        }
      }
    }

    breakfastBig: allShopifyCollection(
      filter: { handle: { eq: "breakfastbundlebig" } }
    ) {
      edges {
        node {
          products {
            id
            title
            variants {
              shopifyId
              price
            }
            images {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    src
                  }
                }
              }
            }
          }
          title
        }
      }
    }

    breakfastSmall: allShopifyCollection(
      filter: { handle: { eq: "breakfastbundlesmall" } }
    ) {
      edges {
        node {
          products {
            id
            title
            variants {
              shopifyId
              price
            }
            images {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    src
                  }
                }
              }
            }
          }
          title
        }
      }
    }
  }
`
