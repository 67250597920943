import styled from "styled-components"
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import RelatedProduct from "./RelatedProduct"
import { useRelatedProducts } from "../../hooks/"
import BodyWrapper from "../BodyWrapper"

const Related = ({ data, product }) => {
  const { relatedProducts } = useRelatedProducts({ data, product })

  return (
    <BodyWrapper child>
      <Container>
        <div className="dotted-Border">
          <h1 className="related-title">Other people bought</h1>
        </div>
        <div className="related-products">
          {relatedProducts.map(relatedItem => {
            return (
              <RelatedProduct key={relatedItem.handle} product={relatedItem} />
            )
          })}
        </div>
      </Container>
    </BodyWrapper>
  )
}

export default Related

const Container = styled.div`
  @media (min-width: 1025px) {
    width: 80vw;
    margin: 0 auto;
  }

  @media (min-width: 1500px) {
    width: 100%;
  }

  margin: 0 auto;
  .dotted-Border {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    border-bottom: 1px solid var(--darkBlue);
  }
  .related-title {
    margin: 0 0 30px 0;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--darkBlue);
    text-align: center;
  }

  div {
    @media (max-width: 768px) {
      flex-direction: column;
    }
    display: flex;
    justify-content: center;
  }

  .related-products {
    align-items: center;
  }
  .item {
    width: 100%;
    height: 300px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
    color: var(--darkBlue);
    font-weight: bold;
  }

  .productImg {
    width: 100%;
    height: 100%;
  }
  .title-container {
    height: 50px;
    width: 100%;
    margin-bottom: 30px;
    p {
      margin: 0;
    }
  }

  @media (max-width: 1024px) {
    margin: 50px auto;
    width: 90%;
  }
`
