import styled from "styled-components"

export const Button = styled.button`
  margin: 0 10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid var(--darkBlue);

  :hover {
    cursor: pointer;
  }

  img {
    width: 15px;
  }

  @media (max-width: 1024px) {
    width: 55px;

    img {
      width: 10px;
    }
  }
`

export const ZoomContainer = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 1111;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.9);

  button {
    &:hover {
      cursor: pointer;
    }
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    margin: 0;
    height: 70%;
  }
  .close {
    height: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 100px;

    button {
      color: var(--pink);
      font-weight: bolder;
      background-color: white;
      border: 1px solid var(--pink);
      border-radius: 50%;
      width: 45px;
      height: 45px;

      :hover {
        border: 2px solid var(--pink);
      }
    }
  }
`

export const Wrapper = styled.div`
  min-height: 100vh;
  max-width: 1300px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

export const ImgContainer = styled.div`
  width: 500px;
  height: 500px;

  .detailImg:hover {
    cursor: zoom-in;
  }

  .thumbnail-container {
    overflow-x: scroll;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin-top: 10px;
    // flex-wrap: wrap;

    @media (max-width: 1024px) {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }
`

export const ProductContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 100px 0 200px 0;

  .ratings {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .add-review {
    color: var(--darkBlue);
    text-decoration: underline;
    margin-left: 15px;
    background: transparent;
    border: none;
    font-size: 1em;
  }

  .assets-div {
    display: flex;
    align-items: center;
    // min-width: 40vw;
    justify-content: center;
    @media (min-width: 1500px) {
      padding: 0 30px 0 0;
      // min-width: 50%;
    }
  }

  .info-div {
    width: 40%;
  }

  .title {
    color: var(--pink);
    text-transform: uppercase;
    font-weight: bolder;
    margin: 0.67em 0;
  }

  .price {
    color: var(--pink);
    font-weight: bolder;
    margin: 0.67em 0;
  }

  .description {
    color: var(--darkBlue);
    font-weight: 500;
    margin: 0.67em 0;

    ul {
      margin-top: 10px;
      list-style-position: inside;
    }
  }

  .btn-div {
    display: flex;
    width: 45%;
    background: white;
    border-radius: 20px;
    color: var(--pink);
    border: 2px solid var(--pink);
    align-items: center;
    font-weight: bolder;
    justify-content: center;

    p {
      margin: 0;
    }

    button {
      width: 40%;
      padding: 15px 12px;
      background: transparent;
      color: var(--pink);
      border: none;
      font-weight: bolder;
    }
  }

  .customize-btn {
    width: 40%;
    background-color: white;
    border: 2px solid var(--pink);
    color: var(--pink);
    font-size: 0.9em;
    font-weight: bolder;
    text-transform: uppercase;

    border-radius: 20px;
    padding: 15px 25px;

    &:hover {
      background-color: var(--pink);
      color: white;
    }
  }

  .add-to-cart-btn {
    width: 45%;
    background-color: var(--lightBlue);
    color: white;
    font-size: 0.9em;
    font-weight: bolder;
    text-transform: uppercase;
    border: none;
    border-radius: 20px;
    padding: 15px 25px;
  }

  .select-div {
    margin-top: 20px;
  }

  .size {
    margin-right: 20px;

    color: var(--pink);
  }

  .select-size {
    font-size: 0.9em;
    width: 45%;
    padding: 10px 25px;
    color: var(--pink);
    border: 2px solid var(--pink);

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--pink);
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--pink);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--pink);
    }

    :focus {
      outline: none;
    }
    text-align: center;

    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='20' height='15' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='Ruby'/></g></svg>")
      no-repeat;
    background-position: right 10px top 50%;
  }

  @media (max-width: 1024px) {
    margin: 20px 0;
    flex-direction: column;
    text-align: center;
    align-items: center;

    .select-div {
      display: flex;
      flex-direction: column;
      /* justify-content: center; */
      align-items: center;
    }

    .select-size {
      width: 100%;
      padding: 10px 40px;
    }
    .assets-div {
      width: 100%;
      height: 100%;
    }
    .ratings {
      justify-content: center;
    }
    .info-div {
      width: 90%;
    }
    .btn-div {
      p {
        font-size: 1.4em;
      }
      border: none;
      display: flex;
      justify-content: space-evenly;
      button {
        border-radius: 50%;
        border: 1px solid var(--pink);
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }
    }
  }
`
